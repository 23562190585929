<template>
  <div v-if="isPermission && isEditMember">
    <CCard>
      <CCardBody>
        <div style="width:15%">
              <CButton id="editMemberBackButton" block color="light" @click="goback()" class="align-items-center">
                <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
              </CButton>
          </div>
        
          <div class="d-flex mb-3">
          
          <div class="pl-4">
            <strong id="editMemberError" class="text-danger font-weight-bold text-left" v-if="errors !== ''">
              {{ errors }}
            </strong>
            <strong id="editMemberSuccessAlert" class="text-success font-weight-bold text-left" v-if="successAlert !== ''">
              {{ successAlert }}
            </strong>
          </div>
        </div>

        <table style="width:100%;">
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td style="width:12%">
              <strong>
                {{ $t('firstname') }} - {{ $t('lastname') }}<span style="color: red;"> *</span>
              </strong>
            </td>
            <td class="p-2">
              <input id="editMemberFirstname" style="border:none" type="text" class="form-control" v-model="fullname" />
            </td>
          </tr>
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td>
              <strong>
                {{ $t('tel') }}
              </strong>
            </td>
            <td class="p-2">
              <input id="editMemberPhone" style="border:none" type="text" class="form-control" v-model="phone" />
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                {{ $t('email') }}
              </strong>
            </td>
            <td class="p-2">
              <input id="editMemberEmail" style="border:none" type="text" class="form-control" v-model="email" />
            </td>
          </tr>
        </table>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <ul class="list-group list-group-flush">
          <li id="editMemberAddAddress" class="list-group-item p-1" @click="addAddress()">
            <strong style="float:left">
              {{ $t('address') }}
            </strong>
            <span style="float:right" class="text-muted">
              <span> {{ address }} {{ $t('item') }} </span>
              <i class="fa fa-chevron-right "></i>
            </span>
          </li>
        </ul>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <table style="width:100%">
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td style="width:12%; height: 5%; margin-bottom: 3%;">
              <strong>
                {{ $t('dayMYBirth') }}
              </strong>
            </td>
            <td class="p-2" style="height: 5%;">
              <div id="editMemberBirthDatePicker" class="form-group datepicker-container" style="width: 100%; margin-bottom: -0.1cm;">
                <VueDatePicker color="#29B46B" :placeholder="$t('chooseBirthDate')" :locale="getDateLocale()"
                  :visible-years-number="90" v-model="dateOfBirth" format="DD-MM-YYYY" />
              </div>
            </td>
          </tr>
          <tr style="border-bottom: 1px solid #ECECEC;">
            <td>
              <strong>{{ $t('sex') }}</strong>
            </td>
            <td class="p-2">
              <select id="editMemberSex" v-model="sex.id" class="custom-select">
                <option disabled value="null">-</option>
                <option :value="option.id" v-for="option in sexoptions" :key="option.id">{{ $t(option.name) }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                {{ $t('customerCate') }}
              </strong>
            </td>
            <td class="p-2">
              <select id="editMemberCate" class="form-control" style="border:none" disabled>
                <option value="0"></option>
                <option value="1"></option>
              </select>
            </td>
          </tr>
        </table>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <strong>
              {{ $t('contactAddress') }}
            </strong>
          </div>
          <!-- <div id="editMemberEditAddress" class="text-right text-top" >
            <i class="fi fi-rr-edit"></i>{{ $t('edit') }}
          </div> -->
        </div>
        <textarea id="editMemberEditContactAddress" rows="5" class="form-control"  style="white-space: pre;" v-model="contactAddress">
        </textarea>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <strong>
              {{ $t('txtTaxAddress') }}
            </strong>
          </div>
          <div id="editMemberEditAddress" class="text-right text-top" @click="editTaxAddress()">
            <i class="fi fi-rr-edit"></i>{{ $t('edit') }}
          </div>
        </div>
        <textarea rows="5" class="form-control" readonly style="white-space: pre;" v-model="msgAddress">
        </textarea>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <strong>
          {{ $t('reason') }}
        </strong>
        <textarea rows="5" class="form-control" v-model="note"></textarea>
      </CCardBody>
    </CCard>
    <CRow class="mb-3">
      <CCol>
        <CButton id="editMemberSave" block v-if="isEditMember" color="success" class="px-4 text-white" @click="validate()">
          {{ $t('saveMember') }}
        </CButton>
        <CButton id="editMemberSaveDisabled" block v-else color="success" class="px-4 text-white" disabled>
          {{ $t('saveMember') }}
        </CButton>
      </CCol>
      <CCol>
        <CButton id="editMemberDelete" block v-if="isDeleteMember" color="danger" class="px-4 text-white" @click="deleteModal = true">
          {{ $t('deleteMember') }}
        </CButton>
        <CButton id="editMemberDeleteDisabled" block v-else color="danger" class="px-4 text-white" disabled>
          {{ $t('deleteMember') }}
        </CButton>
      </CCol>
    </CRow>
    <CModal :show.sync="deleteModal" id="editMemberDeleteModal" :footer="footer" centered :title="$t('confirmDeleteMember')" color="danger">
      <h5 class="text-center">
        <b>
          {{ $t('dialogDeleteMember') }} {{ firstname }} {{ $t('yesOrNo') }} ?
        </b>
      </h5>
      <template #footer>
        <CRow class="justify-content-center col-md-12">
          <CCol col="4">
            <CButton id="editMemberConfirmDelete" color="danger" block @click="deleteMember()" v-if="loadingButton === false">
              {{ $t('confirm') }}
            </CButton>
            <CButton color="danger" block v-else-if="loadingButton === true" disabled>
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="2"></CCol>
          <CCol col="4">
            <CButton id="editMemberCancelDelete" color="light" @click="deleteModal = false" block>
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
    <CModal :show.sync="popupModal" id="editMemberSaveModal" :footer="footer" centered :title="$t('saveMember')" color="success">
      <h5 class="text-center">
        <b>{{ $t('confirmSaveMember') }} ?</b>
      </h5>
      <template #footer>
        <CRow class="justify-content-center col-md-12">
          <CCol col="4">
            <CButton id="editMemberConfirmSave" color="success" block @click="updateMember()" v-if="loadingButton === false">
              {{ $t('confirm') }}
            </CButton>
            <CButton  id="editMemberConfirmSaveDisabled"  color="success" block v-else-if="loadingButton === true" disabled>
              <CSpinner color="white" size="sm" />
            </CButton>
          </CCol>
          <CCol col="2"></CCol>
          <CCol col="4">
            <CButton  id="editMemberCancelSave"  color="light" @click="popupModal = false" block>
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </div>
  <div v-else id="editMemberNoPermission">
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import permis from '@/util/permission'
import moment from 'moment'

export default {
  data() {
    return {
      calendarRender: 0,
      data: {},
      firstname: null,
      lastname: '',
      postalCode: '',
      phone: null,
      fullname: '',
      email: '',
      dateOfBirth: '',
      popupModal: false,
      detailModal: false,
      deleteModal: false,
      footer: '',
      validateAlert: '',
      loadingButton: false,
      successAlert: '',
      deletedAt: null,
      errors: '',
      note: '',
      id: '',
      taxId: '',
      branchName: '',
      msgAddress: '',

      sexoptions: [
        {
          id: 0,
          name: 'male',
        },
        {
          id: 1,
          name: 'female',
        },
        {
          id: 2,
          name: 'notSpectified',
        },
      ],
      sex: '',
      parseSex: '',
      invoiceAddress: '',
      address: 0,
      oldaddress: '',
      contactAddress: ''
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    isPermission() {
      return permis.findPermissionRead('member', '/member/data')
    },
    isEditMember() {
      return permis.findPermissionEdit('member', '/member/data')
    },
    isDeleteMember() {
      return permis.findPermissionRemove('member', '/member/data')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    objectId() {
      return this.$route.params.objectId
    },
  },
  created() {
    this.getMemberByObjectId()
    this.getAddressByMemberObjectId()
  },
  mounted() {
    this.generateMsgAddress();
  },
  methods: {
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: 'de' }
      }
    },
    editTaxAddress() {
      this.$router.push('/member/' + this.objectId + '/editmember/editTaxInvoiceAddress')
    },
    addAddress() {
      this.$router.push('/member/' + this.objectId + '/address')
    },
    validate() {
      this.successAlert = ''
      if (!this.firstname || !this.lastname) {
        this.validateAlert = this.$i18n.t('validateInfomation')
      } else {
        this.validateAlert = ''
        this.popupModal = true
        this.errors = ''
        return true
        // if (!this.validatePhone(this.phone)) {
        //   this.errors = this.$i18n.t('validateTel')
        // } else {
        //   if (this.email !== '') {
        //     if (!this.validEmail(this.email)) {
        //       this.errors = this.$i18n.t('validateEmail')
        //     } else {
        //       this.validateAlert = ''
        //       this.popupModal = true
        //       this.errors = ''
        //       return true
        //     }
        //   } else if (this.email === '') {
        //     this.validateAlert = ''
        //     this.popupModal = true
        //     this.errors = ''
        //     return true
        //   }
        // }
      }
    },
    getMemberByObjectId() {
      const uid = this.uid;
      const objectId = this.objectId;

      let url = '/api/v1.0/' + uid + '/Shop/getmemberbyobjectId/' + objectId + '/data';

      pos({
        method: 'get',
        url: url,
      })
      .then((res) => {
        let doc = res.data.data.documents;

        if (doc) {
          this.data = doc;
          this.firstname = doc.firstname || doc.firstName || '-';
          this.lastname = doc.lastname|| doc.lastName || '-';
          this.phone = doc.phone;
          this.email = doc.email;
          this.dateOfBirth = doc.dateOfBirth;
          this.deletedAt = doc.deletedAt;
          this.isMember = doc.isMember;
          this.note = doc.note;
          this.invoiceAddress = doc.address;
          this.id = doc.id;
          this.fullname = doc.name || `${doc.firstname || doc.firstName || ''} ${doc.lastname || doc.lastName || ''}`.trim() || '-';
          this.taxId = doc.taxID || '-';
          this.contactAddress = doc.contactAddress || '-'
          // Check if sex is received as a number
          this.sex = (doc.sex && doc.sex.id !== undefined) 
            ? { id: doc.sex.id, name: doc.sex.name } 
            : { id: 2, name: 'notSpectified' }
          if (doc.branchName === undefined) {
            this.branchName = '';
          } else {
            this.branchName = doc.branchName
          }

          if (!this.fullname || !this.invoiceAddress) {
            this.msgAddress = '';
          } else {
            if (doc.branchName === '') {
              this.msgAddress = `${this.$t('customerName')} \n${this.fullname} \n${this.$t('customerInfo')} \n${this.taxId} \n${this.invoiceAddress}`;
            } else {
              this.msgAddress = `${this.$t('customerName')} \n${this.fullname} ${this.branchName} \n${this.$t('customerInfo')} \n${this.taxId} \n${this.invoiceAddress}`;
            }
          }
        } else {
          console.log('error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },

    updateMember() {
      this.loadingButton = true
      const uid = this.uid
      const selectedSex = this.sexoptions.find(option => option.id === this.sex.id);
      const formattedDateOfBirth = moment(this.dateOfBirth).toISOString();
      const nameParts = this.fullname.trim().split(" ");
      const firstname = nameParts.length > 0 ? nameParts[0] : "";
      const lastname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
      let data = {
        name: this.fullname,
        firstname: firstname,
        lastname: lastname,
        phone: this.phone,
        email: this.email,
        dateOfBirth: formattedDateOfBirth,
        sex: selectedSex,
        shopObjectId: this.shopObjectId,
        objectId: this.objectId,
        note: this.note,
        address: this.invoiceAddress,
        id: this.id,
        contactAddress: this.contactAddress
      }
      let headers = { shopObjectId: this.shopObjectId }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Shop/updatemember/data',
        data: data,
        headers: headers,
      })
        .then((res) => {
          console.log(data)
          this.loadingButton = false
          this.popupModal = false
          this.successAlert = this.$i18n.t('updateMemberSuccess')
          this.$router.push('/member/' + this.objectId + '/getdetail')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteMember() {
      const uid = this.uid
      let data = {
        objectId: this.objectId,
        shopObjectId: this.shopObjectId,
      }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Member/softdelete',
        data: data,
      })
        .then(() => {
          this.loadingButton = false
          this.popupModal = false
          this.successAlert = this.$i18n.t('deleteSuccess')
          this.$router.push('/member/data')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    validEmail(email) {
      let EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return EMAILREG.test(email)
    },
    validatePhone(phone) {
      const MOBILEREG = /^[0]\d{9}$/
      return MOBILEREG.test(phone)
    },
    generateMsgAddress() {
      this.msgAddress = `${this.$t('customerName')} \n${this.fullname} ${this.branchName} \n${this.$t('customerInfo')} \n${this.taxId} \n${this.invoiceAddress}`;
    },
    goback() {
      this.$router.push('/member/' + this.objectId + '/getdetail')
    },
    getAddressByMemberObjectId() {
      const objectId = this.objectId
      let url = '/api/v1.0/address/getbymember/' + objectId

      pos({
        method: 'get',
        url: url,
      })
        .then((res) => {
          this.address = res.data.data.length
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
